import React from "react";  
import ConfigJSONViewer from "../dialogs/configJSONViewerDialog";

export default function ButtonWithErrors({ label, config }) {

    const [open, setOpenDialog] = React.useState(false);
    const[configData, setConfigData] = React.useState('');

    const closeDialog = () => {
        setOpenDialog(false);
      };

    const showConfig= ()=>{
        setConfigData(config['errors']);
        setOpenDialog(true);
    }

    return (
        <>
            {open ?(<ConfigJSONViewer open= {open} onClose={closeDialog} data={configData}></ConfigJSONViewer>):null}
            <div onClick={showConfig} class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined">
                <span>{label}</span>
                <span class="error-circle">{config?.errorCount}</span>
            </div>
        </>
    );
 
}