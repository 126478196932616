// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/crm-analytics-topbanner.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container{
  height: 100%;
  display: table;
  width: 100%;
}

.Login {
    text-align: center;
 }
 form {
    margin: 2% 10%;
    border: 1px solid rgb(216, 221, 230);
    padding: 7% 10%;
    background: white;
    border-radius: 0.25rem;
 }
 
 .login-header {
   background-color: white;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: black;
 }

 #left {
    display: table-cell;
    vertical-align: middle;
 }

 #right {
  display: table-cell;
  width: 60%;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
    background-size: cover;
 }

 .standard_logo {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  max-width: 180px;
  max-height: 113px;
}`, "",{"version":3,"sources":["webpack://./src/login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;AACb;;AAEA;IACI,kBAAkB;CACrB;CACA;IACG,cAAc;IACd,oCAAoC;IACpC,eAAe;IACf,iBAAiB;IACjB,sBAAsB;CACzB;;CAEA;GACE,uBAAuB;GACvB,iBAAiB;GACjB,aAAa;GACb,sBAAsB;GACtB,mBAAmB;GACnB,uBAAuB;GACvB,6BAA6B;GAC7B,YAAY;CACd;;CAEA;IACG,mBAAmB;IACnB,sBAAsB;CACzB;;CAEA;EACC,mBAAmB;EACnB,UAAU;EACV,mDAAuD;EACvD,4BAA4B;IAC1B,sBAAsB;CACzB;;CAEA;EACC,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".login-container{\n  height: 100%;\n  display: table;\n  width: 100%;\n}\n\n.Login {\n    text-align: center;\n }\n form {\n    margin: 2% 10%;\n    border: 1px solid rgb(216, 221, 230);\n    padding: 7% 10%;\n    background: white;\n    border-radius: 0.25rem;\n }\n \n .login-header {\n   background-color: white;\n   min-height: 100vh;\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: center;\n   font-size: calc(10px + 2vmin);\n   color: black;\n }\n\n #left {\n    display: table-cell;\n    vertical-align: middle;\n }\n\n #right {\n  display: table-cell;\n  width: 60%;\n  background: url(../images/crm-analytics-topbanner.webp);\n  background-repeat: no-repeat;\n    background-size: cover;\n }\n\n .standard_logo {\n  margin-left: auto;\n  margin-right: auto;\n  vertical-align: middle;\n  max-width: 180px;\n  max-height: 113px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
