import React, { useEffect  } from 'react';
import Cookies from 'js-cookie';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { TreeView } from "@mui/x-tree-view/TreeView";
import FolderIcon from "@mui/icons-material/Folder";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AdminHeader from "./adminHeader";
import DynamicTreeView from '../common/treeViewComponent';
import Box from "@mui/material/Box";
import { TEMPLATE_SERVER_URL } from '../common/constants';

import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Button } from '@mui/base';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    textAlign: 'left'
  },
  leftDiv: {
    width: '30%',
    height: '100%',
    textOverflow: 'ellipsis',
    backgroundColor: '#f0f0f0', // Background color for the left div
  },
  rightDiv: {
    flex: 1,
    backgroundColor: '#e0e0e0', // Background color for the right div
  },
}));


function AdminCacheViewer() {

    const adminLogin=Cookies.get('adminLogin');

    const classes = useStyles();

    const [dirJSONS, setDirJSON] = React.useState({});
    const [fileContent, setFileContent] = React.useState('');
   

    useEffect(()=>{
        if(adminLogin){

            getData(`/admin/cache?adminLogin=${adminLogin}`)
                .then(data =>{
                    setDirJSON(data);
            }).catch(error=>{

            }); 
        }

    }, [adminLogin])

    async function getData(url='', data = {}, responsePattern) {
        // Default options are marked with *
        const response = await fetch(TEMPLATE_SERVER_URL +  url, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          
        });
        if(responsePattern === 'text'){
            return response.text();
        }
        else {
            return response.json(); // parses JSON response into native JavaScript objects
        }        
    }


      const previewFilePath = (path) => {
        console.log(path);
        if(adminLogin){

            getData(`/admin/cache/previewFile?adminLogin=${adminLogin}&path=${path}`, {}, 'text')
                .then(data =>{
                    setFileContent(data);
                  
            })
            .catch(error => {
                setFileContent('Some error in fetching file.')
            });     
       }
    }


    const cleanCacheFolder = async(rowData)=>{
        if(adminLogin) {
            var url = `${TEMPLATE_SERVER_URL}/admin/cache?adminLogin=${adminLogin}`;
            const response = await fetch(url, {
                mode: 'cors',
                method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                headers: {
                  'Content-type': 'application/json',
                  'Access-Control-Allow-Origin': '*'
                },
                
              });
              if(response.status === 200){
                console.log('Deleted successfuly!');
                response.json().then((dirJSON)=>{
                    setDirJSON(dirJSON);
                }).catch((error)=>{
                    setDirJSON({});
                });
              }
              else{
    
                console.log('Not able to delete!');
              }
        }
         
        
    }

    
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AdminHeader />
            </Box>
            <div style={{textAlign : "right", marginRight: "2%"}}  >
                <Button variant="contained" onClick={cleanCacheFolder}>Clean Storage</Button>
            </div>
            <div className={classes.container}>
                <Paper className={classes.leftDiv}>
                    <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 400, textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <TreeView defaultCollapseIcon={<ChevronRightIcon />} defaultExpandIcon={<ExpandMoreIcon />}>
                            <TreeItem
                                key={dirJSONS.path}
                                nodeId={dirJSONS.path}
                                label={dirJSONS.name}
                                icon={dirJSONS.type === "folder" ? <FolderIcon /> : null}
                            >
                            <DynamicTreeView nodes={dirJSONS.children} previewFile={previewFilePath}/>
                            </TreeItem>
                        </TreeView>
                    </Box>
                </Paper>
                <Paper className={classes.rightDiv}>
                    <pre>
                        <p>{fileContent}</p>
                    </pre>
                </Paper>
            </div>
        </>
    );


}

export default AdminCacheViewer;