import React from 'react';
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";

export default function DynamicTreeView(props) {
  const handleNodeClick = (event, nodeId, type) => {
    // Handle the click event for the node with nodeId
    console.log(`Node ${nodeId} clicked.`);
    // props.previewFile(nodeId);
    if (typeof props.previewFile === 'function' && type!=="folder") {
      props.previewFile(nodeId);
    }
    event.preventDefault();
  };

  return (
    <Box>
          {props.nodes.map((node) => (
            <TreeItem
              key={node.path}
              nodeId={node.path}
              label={<div className="fileName">{node.name}</div>}
              icon={node.type === "folder" ? <FolderIcon /> : null}
              onClick={(event) => handleNodeClick(event, node.path, node.type)}
            >
                { node.type === "folder" && (
                    <DynamicTreeView nodes={node.children} previewFile={props.previewFile} />
                )}
          </TreeItem>
          ))}
       
    </Box>
  );
};