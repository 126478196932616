import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@mui/material';

export default function SelectAllApps(props) {
  const [selectedFolderIds, selectFolders] = React.useState(props.data.folders.map(folder => folder.label));
  const [errorFlag, updateErrorFlag] = React.useState(false);

  function selectApp(e) {
    const ischecked = e.target.checked;
    const dataid = e.target.id;
    updateErrorFlag(false);
    if (dataid === 'selectAllFolders') {
      selectFolders(ischecked ? props.data.folders.map(folder => folder.label) : []);
    } else {
      selectFolders(prevSelected => {
        if (ischecked) {
          return [...prevSelected, dataid];
        } else {
          return prevSelected.filter(id => id !== dataid);
        }
      });
    }
  }

  function decoupleApps(event) {
    if (selectedFolderIds.length === 0) {
      updateErrorFlag(true);
      return;
    }
    const selectedFoldersToDecouple = props.data.folders.filter(folder => selectedFolderIds.includes(folder.label));
    props.decoupleApps(selectedFoldersToDecouple);
    event.preventDefault();
  }

  console.log(props.data);
  
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={400}
      >
        <DialogTitle id="scroll-dialog-title" style={{ background: 'red', color: 'white' }}>Select Apps For Decouple</DialogTitle>
        <DialogContent dividers={true} style={{ maxHeight: '400px' }}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <div style={{ visibility: (errorFlag ? 'visible' : 'hidden'), textAlign: 'center' }}><span className='error-msg'>Select at least one app to decouple!</span></div>
            <div><span>Select apps to decouple from this template.</span></div>
            <FormControlLabel
              control={<Checkbox
                margin="selectAllFolders"
                name="selectAll"
                checked={selectedFolderIds.length === props.data.folders.length}
                data-id="selectAllFolders"
                id="selectAllFolders"
                onChange={selectApp}
              />
              }
              label="All Apps"
            />
            <hr />
            <ul style={{ maxHeight: '250px', overflow: 'hidden', overflowY: 'scroll', listStyle: 'none' }}>
              {
                props.data.folders.map((folder) => (
                  <li key={folder.label}>
                    <FormControlLabel
                      control={<Checkbox
                        margin="normal"
                        data-id={folder.label}
                        value={folder.label}
                        id={folder.label}
                        checked={selectedFolderIds.includes(folder.label)}
                        onChange={selectApp}
                      />
                      }
                      label={folder.label}
                    />
                  </li>
                ))
              }
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
          <Button variant="contained" onClick={decoupleApps}>
            Decouple Apps
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
