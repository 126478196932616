// class RetrieverModal extends Component {


// }

import React, { useState, useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Button, Link } from '@material-ui/core';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";


import logo from './img/crma_logo.png';
import { Stack } from '@mui/material';




function AppHeader(props) {

    const userInfo = {
        username: Cookies.get('username') || '', 
        fullName: Cookies.get('userFullName') || '', 
    };

    


    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

        let navigate = useNavigate();
        

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleCloseToggle = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
            }
            setOpen(false);
        };

        const handleLogout = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
            }
            removeCookies();
            navigate('/');
            setOpen(false);
        };

        const removeCookies = () => {
            Cookies.remove('accessToken');
            Cookies.remove('instanceUrl');
            Cookies.remove('username');
            Cookies.remove('userFullName');

        }

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
              event.preventDefault();
              setOpen(false);
            }
          }

          useEffect(() => {
            if(!userInfo.username){
                navigate('/');
            }
        }, [userInfo])

        

    return (
        
        <div style={{maxWidth: '100%'}}>
            {
                (() => {
                    if(!userInfo.username === ''){
                        navigate('/');
                    }
                })()
            }
            <style>{`
                .blue {color: #3789d1}
                .white {color: white}
            `}</style>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar style={{backgroundColor: "#3789d1", color: "white"}} position="static">
                  <Toolbar>
                    <img src={logo} height={"42px"} alt="Analytics Studio" />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Analytics Studio
                        </Typography>
                        <Stack direction="row" spacing={4} style={{ "margin-right" : "30px"}} >
                            <Typography variant="h6">
                                <Link href="/table" color="inherit" underline='none'>{"Home"}</Link>
                            </Typography>
                            <Typography variant="h6">
                                <Link href="/retriever" color="inherit" underline='none'> {"Retriever"}</Link>
                            </Typography>
                        </Stack>

                        <Button
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <Avatar alt={userInfo.fullName} > {userInfo.fullName[0]}</Avatar>
                        </Button>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ "z-index" : "9"}}  
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                <ClickAwayListener onClickAway={handleCloseToggle}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} >
                                        <MenuItem >
                                            {userInfo.fullName}
                                        </MenuItem>
                                        <MenuItem >
                                            {userInfo.username}
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                            <Logout fontSize="small" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                                </Paper>
                            </Grow>
                            )}
                        </Popper>

                  </Toolbar>
                </AppBar>
            </Box>
        </div>
    );


}


export default AppHeader;