import React, {useState} from "react";
import Button from "@mui/material/Button";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";


const VariableInputDialog = (props) => {

    const [inputValues, setInputValues] = useState(props.data.defaultVariables);

    const saveVariables = (event) => {
        // Call the parent callback function
        event.target.updateDefaultValues = inputValues;
        event.target.folderName = props.data.folderName;
        props.createSaveVariableRequest(event.target);
        event.preventDefault();
    }

    const updateValue=(index, value)=> {
        const newInputValues = [...inputValues];
        newInputValues[index].value= value;
        setInputValues(newInputValues);
    }

    const createTextField = (item, index)=> <TextField margin="dense" label={item.name} value={item.value} onChange={(e)=>updateValue(index, e.target.value)} fullWidth/>;


    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="signModalTitle"
            aria-describedby="signModalDescription"
            minWidth="200px"
        >
            <DialogTitle id="dialog" style={{background:'#1976d2', color:'white'}}>{"Variable JSON Required Default Value"}</DialogTitle>
            <DialogContent>
                <p>Following variables are marked required inside variables.json, but not having any default value.</p>
                <p>Please provide default value for these variables to proceed.</p>
                {
                    inputValues.map((item, index)=>{
                       return createTextField(item, index);
                    })
                }

            </DialogContent>
            <DialogActions>
                <Button raised onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant="contained" raised onClick={saveVariables}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default VariableInputDialog