import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AdminHeader from "./adminHeader";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

import { TEMPLATE_SERVER_URL } from "../common/constants";


function AdminHome() {

    const adminLogin=Cookies.get('adminLogin')

    let navigate = useNavigate();

    const [orgList, setOrgList] = React.useState([]);
    // const [retrieveList, setRetrieveList]=React.useState([]);

    useEffect(()=>{
        if(adminLogin){

            getData('/admin/orgs',{ adminLogin  })
                .then(data =>{
                    setOrgList(data)
            }); 
        }
        else {
          navigate('/admin');
        }

    }, [adminLogin])

    async function getData(url='', data = {}) {
        // Default options are marked with *
        const response = await fetch(TEMPLATE_SERVER_URL +  url, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          
        });
        return response.json(); // parses JSON response into native JavaScript objects
      }


    function showRetrieveRequests(rowData){
        if(rowData?.retrieveMetadata.length > 0){
            return (
                <div>
                    <TableContainer component={Paper}>
                        <Table size="small" style={{marginLeft: '50px',backgroundColor: '#f3efef'}}>
                            <TableHead style={{backgroundColor: '#d7d2d2'}}>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowData.retrieveMetadata.map((data)=>(
                                        <TableRow>
                                            <TableCell component="th" scope="row">{data.name}</TableCell>
                                            <TableCell>{data.createdOn}</TableCell>
                                            <TableCell>{data.status.status_text}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                            
                        </Table>
                    </TableContainer>
                </div>
            )
        }
        else return (<p style={{'textAlign': 'center', backgroundColor:"#f3efef", marginLeft: '50px', paddingTop: '25px', minHeight: '50px'}}>No Retrieve Request created for this org!</p>);
    }

     const deleteOrg = async(rowData)=>{
        let url = rowData.organizationId ? TEMPLATE_SERVER_URL + `/admin/orgs/${rowData.organizationId}` : TEMPLATE_SERVER_URL + "/admin/orgs";
        const response = await fetch(url, {
            mode: 'cors',
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          });
          if(response.status === 200){
            console.log('Deleted successfuly!');
          }
          else{
            console.log('Not able to delete!');
          }
        
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AdminHeader />
            </Box>
            <p></p>
            <div style={{textAlign : "right", marginRight: "2%"}}  >
                <Button variant="contained" onClick={deleteOrg}>Clear All Orgs</Button>
            </div>
            <div style={{maxWidth: '100%', marginTop:"5%"}}>
                
                <MaterialTable
                    columns={[
                        { title: 'OrgId', field: 'organizationId' },
                        { title: 'Server Url', field: 'serverUrl' },
                        { title: 'User Email', field: 'userEmail' },
                        { title: 'Total Requests', field: 'total_requests', width: '15%'}
                    ]}
                    data={orgList}
                    detailPanel = { (rowData)=>showRetrieveRequests(rowData)}
                    title={"Organization List"}
                    options={{
                        paging: false,
                        selection: false,
                        search: false,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'Delete This Org',
                            onClick: (event, rowData) => deleteOrg(rowData)
                          })
                      
                  ]}
                    
                />
            </div>
        </>
    );


}

export default AdminHome;