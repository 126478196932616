import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ToastAlert(props) {
//   const [open, setOpen] = React.useState(false);
//   const [snackMessage, setSnackMessage] = React.useState("");
//   const [snackVariant, setSnackVariant] = React.useState("");

//   const handleClick = () => {
//     setSnackMessage("request raised");
//     setSnackVariant("warning");
//     setOpen(true);
//   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      // return;
    }
    props.closeSnackBar(false);
  };

  return (
    
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={props.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Alert
          onClose={handleClose}
          severity={props.snackVariant}
          sx={{ width: "100%" }}
        >
          {props.snackMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
 