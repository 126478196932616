import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfigJSONViewer({ open, onClose, data }) {

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  console.log(data);
  return (
    <div>
      <Dialog
        open={true}
        onClose={onClose}
        scroll= "paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={400}
      >
        <DialogTitle id="scroll-dialog-title" style={{color:'red'}}>Errors</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
          <pre>
              { Object.entries(data).map((item) => {
                return( 
                <div>
                  <h2>{item[0]}</h2>
                  {item[1].map((obj)=>{
                    return( 
                    <div>
                      <li >{obj.pattern} matches</li>
                      {
                        obj['matches'].map((val)=>{
                          return (<li style={{textAlign:'center'}}>{val}</li>);
                        })
                      }
                      <br/>
                    </div>
                    );
                  
                  }
                  )}
                </div>  
                  );
              }) }
          </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}