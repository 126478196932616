import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@mui/material';

export default function AppsForATemplateDialog(props) {

  const myListStyle = {
    maxHeight :  '250px',
    overflow: 'hidden',
    overflowY: 'scroll'
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      
    }
  }, [props.open]);

  const [selectedFolderIds, selectFolders] = React.useState([]);
  const [errorFlag, updateErrorFlag] = React.useState(false);


  function selectApp(e) {
    const ischecked = e.target.checked;
    const dataid = e.target.id;
    updateErrorFlag(false);
    if (dataid === 'selectAllFolders') {
        if (ischecked === true) {
            selectFolders(folders);
        } else {
            selectFolders([]);
        }
    } else {
        if (ischecked === true) {
            selectFolders((prevalue) => [...prevalue, dataid])
        } else {
            const resultfilter = selectedFolderIds.filter((d, index) => {
                return d !== dataid;
            });
            selectFolders(resultfilter);
        }
    }
  };

  function decoupleApps(event){
      if(selectedFolderIds.length===0){
        updateErrorFlag(true);
        return;
      }
       let selectedFoldersToDecouple =  props.data.folders.filter((folder) => {
            if(selectedFolderIds.indexOf(folder.label)!==-1){
                return folder;
            }
        });
        props.decoupleApps(selectedFoldersToDecouple);
        event.preventDefault();
  }



  let  folders = props.data.folders.map((folder)=> {
    return folder.label;
  });

  console.log(props.data);
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.onClose}
        scroll= "paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={400}
      >
        <DialogTitle id="scroll-dialog-title" style={{background:'red', color:'white'}}>Select Apps For Decouple</DialogTitle>
        <DialogContent dividers={true} style={{maxHeight: '400px'}}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
          <div style={{visibility: (errorFlag?'visible':'hidden'), textAlign:'center'}}><span className='error-msg'>Select atleast one app to decouple!</span></div>
          <div><span>Select apps to decouple from this template.</span></div>
          <FormControlLabel
                            control={ <Checkbox
                                margin="selectAllFolders"
                                name="selectAll"
                                checked={selectedFolderIds.length === folders.length}
                                data-id="selectAllFolders"
                                id="selectAllFolders"
                                onChange={(e)=> {selectApp(e)}}/>
                            
                            }
                            label="All Apps"
                        />
                        <hr/>
          <ul style={myListStyle}>
            {
                folders.map((folder, index)=> {
                    return (
                        <li style={{listStyle: 'none'}}><FormControlLabel
                            control={ <Checkbox
                                margin="normal"
                                data-id={folder} 
                                value={folder} 
                                id={folder}
                                checked={selectedFolderIds.includes(folder)} 
                                onChange={(e)=> {selectApp(e)}}/>
                            }
                            label={folder}
                        />
                        </li>
                        );
                })
            }
          </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
          <Button variant="contained" onClick={decoupleApps}>
                    Decouple Apps
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}