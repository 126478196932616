import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import { DiffEditor } from "@monaco-editor/react";

const DiffEditorModal = (props) => {

    const [options, setEditorOptions] = React.useState({
        fontSize: 13
      })


    const handleClose = ()=>{
        props.handleClose();
    }

    return(
        <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
        >
            <DialogTitle id="dialog" style={{width:'1000px', fontWeight: 'bold'}}>{ "Config Changes Preview" }<hr/></DialogTitle>
            <DialogContent>
                <DialogContentText id="configUploaderText">
                    Preview the Difference in config file.
                </DialogContentText>
                
                <DiffEditor 
                    width="90vh"
                    height="90vh"
                   // options={options}
                    defaultLanguage="json"
                    original={props.originalCode}
                    modified={props.modifiedCode}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default DiffEditorModal;