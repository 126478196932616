// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileLabel {
    font-weight: bold;
    border-bottom: 1px solid #676161;
    background: rgba(0, 0, 0, .3);
    padding: 5px;
    margin: 2px;
    word-wrap: break-word;
}

.fileContent {
    
    height: 648px;
    padding: 5px;
    background: #f3f3f3;
    border-radius: 4px;
    resize: vertical;
    white-space: pre;
    word-wrap: normal;
    overflow-y: scroll;
}

.fileName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/previewTemplate.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gCAAgC;IAChC,6BAA6B;IAC7B,YAAY;IACZ,WAAW;IACX,qBAAqB;AACzB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".fileLabel {\n    font-weight: bold;\n    border-bottom: 1px solid #676161;\n    background: rgba(0, 0, 0, .3);\n    padding: 5px;\n    margin: 2px;\n    word-wrap: break-word;\n}\n\n.fileContent {\n    \n    height: 648px;\n    padding: 5px;\n    background: #f3f3f3;\n    border-radius: 4px;\n    resize: vertical;\n    white-space: pre;\n    word-wrap: normal;\n    overflow-y: scroll;\n}\n\n.fileName {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    max-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
