import { useState } from "react";
import Button from "@mui/material/Button";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import  FileUpload  from "react-material-file-upload";

const FileUploaderModal = (props) => {

    const [file, setConfigFile] = useState([]);

    const uploadConfig = (event) => {
        event.target.configFile = file[0];
        var fileReader = new FileReader( );
        fileReader.onload = function ( evt ) { 
            setConfigFile([]);
            props.showUploadedConfig(evt.target.result);
            
        };
        fileReader.readAsText( file[0] );
        
        event.preventDefault();
    }

    const handleClose = ()=>{
        setConfigFile([]);
        props.handleClose();
    }

    return(
        <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        >
            <DialogTitle id="dialog" style={{width:'800px', fontWeight: 'bold'}}>{ "Upload Config File" }<hr/></DialogTitle>
            <DialogContent>
                <DialogContentText id="configUploaderText">
                    Upload the config file.
                </DialogContentText>
                <FileUpload value={file} multiple={false} accept={".json"} onChange={setConfigFile}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={uploadConfig}>
                    Upload Config
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default FileUploaderModal;