import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import '../login.css';
import { TEMPLATE_SERVER_URL } from '../common/constants.js'
import { useNavigate } from "react-router-dom";


import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import {APP_LABELS} from '../common/label.js'


function AdminLogin() {
  const [username , setUsername] = useState('')
  const [password , setPassword] = useState('')

  const [errorLogin, setErrorLogin] = useState(false);
  const [errorLoginMsg, setErrorLoginMsg] = useState('');
  const [flagLoader, setFlagLoader] = useState(false);

  let navigate = useNavigate(); 
 
  
  
  
  // function to update state of email with value
  // enter by user in form
  const handleUsernameChange =(e)=>{
        console.log(e.target.value)
        setUsername(e.target.value);
        }
        // function to update state of password with
        // value enter by user in form

        const handlePasswordChange =(e)=>{
        console.log(e.target.value);
        setPassword(e.target.value);
    }


    const handleSubmit =(e)=>{
        let query = TEMPLATE_SERVER_URL + `/admin/login?username=${username}&password=${password}`;
        authenticationRequest(query);
         e.preventDefault();
    }




async function authenticationRequest(url) {
    setErrorLogin(false);
    setErrorLoginMsg('');
    setFlagLoader(true);
    // Default options are marked with *
    fetch(url, {
      mode: 'cors',
      method: 'GET', // * GET, POST, PUT, DELETE, etc.
      headers : new Headers ({
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    })
    .then(response => {
            console.log('response', response);
            return response.json();
     }).then(data => {
            console.log('data', data);
            // Cookies.set('accessToken',data.sessionId, { expires: 1 })
            // var url = new URL(data.serverUrl);
            
            Cookies.set('adminLogin', true, { expires: 1 });
            // Cookies.set('username',data.userName, { expires: 1 });
            // Cookies.set('userFullName',data.userFullName, { expires: 1 })
            // Cookies.set('organizationId',data.organizationId, { expires: 1 })
            // console.log(Cookies.get('accessToken'),"afterset");
            navigate('/admin/home');
            setFlagLoader(false);
     }).catch((e)=> {
            console.log("exception: ", e);
            setErrorLogin(true);
            setErrorLoginMsg(APP_LABELS['LOGIN_ERROR_MSG']);
            setFlagLoader(false);
    });
    
}

const adminLogin=Cookies.get('adminLogin') || false;

useEffect(() => {
    if(adminLogin){
        navigate('/admin/home'); 
    }
}, [adminLogin])

return (
    <div class="login-container">
        <div id="left" className="Login">
            <Box className="progress-bar" sx={{ "display": flagLoader ? 'flex': 'none'}}>
            <CircularProgress  />
            </Box>
            <h3> <img aria-hidden="true" id="logo" class="standard_logo" src=".././logo214.svg" alt="Salesforce" border="0" name="logo"></img></h3>
            <form onSubmit={(e) => {handleSubmit(e)}}>
            {/*when user submit the form , handleSubmit()
                function will be called .*/}
                { errorLogin && (<Alert severity="error">{errorLoginMsg}</Alert>)}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    onChange={(e)=> {handleUsernameChange(e)}}
                    value={username}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e)=> {handlePasswordChange(e)}}
                    value={password}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Log In
                </Button>

            </form>
        </div> 
               
    </div>
);
}

export default AdminLogin;
