import './App.css';
import Auth from './auth.js';
import Table from './table.js';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import Retriever from './retriever';
import ConfigEditor from './configEditor';
import AdminLogin from './admin/adminLogin';
import AdminHome from './admin/adminHome';
import PreviewTemplate from './components/previewTemplate';
import AdminCacheViewer from './admin/adminCacheViewer.js';

function App() {

  return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="/admin" element={<AdminLogin />} />
              <Route path="/admin/home" element={<AdminHome />} />
              <Route path="/admin/cache" element={<AdminCacheViewer />} />
              <Route path="/table" element={<Table />} />
              <Route path="/retriever" element={<Retriever />} />
              <Route path="/editconfig/:templateName" element={<ConfigEditor />} />
              <Route path="/preview/:folderName" element={<PreviewTemplate />} />
            </Routes>
          </BrowserRouter>
        </div >
  );
}


export default App;


