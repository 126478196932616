import React from "react";
import Button from "@mui/material/Button";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { Checkbox, FormControlLabel } from "@mui/material";


const RetrieveRequestModal = (props) => {
    

    const [fetchParentOption, setFetchParentOption] = React.useState(false);
    const [isAutoInstall, setAutoInstall] = React.useState(false);

    const selectParentOptionChange = (event) => {
        setFetchParentOption(!fetchParentOption);
        setAutoInstall(false);
    };

    const handleAutoInstallableChange = (event) => {
        setAutoInstall(!isAutoInstall);
        setFetchParentOption(false); 
    };

    const createRequest = (event) => {
        // Call the parent callback function
        event.target.fetchParentOption = fetchParentOption;
        event.target.isAutoInstall = isAutoInstall;
        event.target.row = props.row;
        props.createRequest(event.target);
        event.preventDefault();
        setFetchParentOption(false);
        setAutoInstall(false);
      
    }
    const handleClose = () => {
        setFetchParentOption(false);
        setAutoInstall(false);
        props.handleClose();
    };
    const isAtLeastOneChecked = isAutoInstall || fetchParentOption;

    return(
     <div>
        <Dialog
            open={props.signOpen}
            onClose={props.handleClose}
            aria-labelledby="signModalTitle"
            aria-describedby="signModalDescription"
            minWidth="200px"
        >
            <DialogTitle id="scroll-dialog-title" style={{background:'#1976d2', color:'white'}}>{"Create Retrieve Request"}</DialogTitle>
            <DialogContent>
                {/*<DialogContentText id="signModalDescription">
                    Select auto install options.
                </DialogContentText>
                 <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <FormHelperText>Select Version</FormHelperText>
                    <Select
                    value={version}
                    onChange={handleVersionChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={"58.0"}>58.0</MenuItem>
                        <MenuItem value={"57.0"}>57.0</MenuItem>
                        <MenuItem value={"56.0"}>56.0</MenuItem>
                        <MenuItem value={"55.0"}>55.0</MenuItem>
                        <MenuItem value={"54.0"}>54.0</MenuItem>
                    </Select>
                    
                </FormControl> 
                <br/> */}
                {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <FormHelperText>Select AutoInstallable</FormHelperText>
                    <Select
                    value={isAutoInstall}
                    onChange={handleAutoInstallableChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                    
                </FormControl> 
                <br/>*/}
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <FormControlLabel
                            control={ <Checkbox
                               
                                name="prepareParent"
                                checked={isAutoInstall}
                                data-id="prepareParent"
                                id="prepareParent"
                                onChange={(e)=> {handleAutoInstallableChange(e)}}/>
                            
                            }
                            label="Is template based on AutoInstall."
                        />
                </FormControl>
                <br/>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <FormControlLabel
                            control={ <Checkbox
                               
                                name="prepareParent"
                                checked={fetchParentOption}
                                data-id="prepareParent"
                                id="prepareParent"
                                onChange={(e)=> {selectParentOptionChange(e)}}/>
                            
                            }
                            label="Prepare parent template."
                        />
                </FormControl>
                
                
            </DialogContent>
            <DialogActions>
                <Button raised onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained"   disabled={!isAtLeastOneChecked} raised onClick={createRequest}>
                    Create Request
                </Button>
            </DialogActions>
        </Dialog>
    </div>
    );
}

export default RetrieveRequestModal