import React, { useState } from "react";
import {useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import Editor from "@monaco-editor/react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, ButtonGroup } from "@material-ui/core";

import { TEMPLATE_SERVER_URL } from './common/constants.js'
import AppHeader from "./AppHeader.js";
import FileUploaderModal from "./common/dialogs/fileUploaderModal.jsx";
import DiffEditorModal from "./common/dialogs/diffEditorModal.jsx";
import ButtonWithErrors from "./common/buttonWithErrors/buttonWithErrors.js";
import { CircularProgress } from "@mui/material";




function ConfigEditor() {

    const [flagLoader, setFlagLoader]= useState(1);
    const accessToken=Cookies.get('accessToken')
    const instanceUrl= Cookies.get('instanceUrl')
    const userInfo = {
          username: Cookies.get('username'), 
          fullName: Cookies.get('userFullName'), 
          organizationId: Cookies.get('organizationId')
    };
    const { templateName } = useParams();

    const[configDataWithErrors, setConfigDataWithErrors] = React.useState();
    const[configData, setConfigData] = React.useState();
    const[configCurrentData, setConfigCurrentData] = React.useState();
    const[modifiedData, setModifiedData] = React.useState('{}');
    const [open, setOpen] = React.useState(false);
    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
    const [options, setEditorOptions] = React.useState({
      fontSize: 15
    })

  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function getCurrentValue() {
   return (editorRef.current.getValue());
  }

    let navigate = useNavigate();

    useEffect(() => {
       if(instanceUrl && accessToken){

            getData('/retrieveConfigFile',{instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: templateName})
                .then(data =>{
                  setConfigDataWithErrors(data);
                  setConfigData(JSON.stringify(data['deserialisedConfigJSON'], undefined, 2))
            })
            .catch(error => {
                setConfigData()
            });     
       }
        else {
          navigate('/');
        }

    },[instanceUrl,accessToken, templateName]);

    async function getData(url='', data = {}) {
      // Default options are marked with *
      beforeRequest();
      const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}&folderName=${data.folderName}`, {
        mode: 'cors',
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        // body:  JSON.stringify(get_templates_body)
        
      });
      postRespone();
      return response.json(); // parses JSON response into native JavaScript objects
    }

    async function postData(url='', data = {}) {
      // Default options are marked with *
      beforeRequest();
      const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}&folderName=${data.folderName}`, {
        mode: 'cors',
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
         body:  JSON.stringify(JSON.parse(data.config))
        
      });
      postRespone();
      return response.json(); // parses JSON response into native JavaScript objects
    }

    const copyContent = async () => {
      try {
        await navigator.clipboard.writeText(configCurrentData);
        console.log('Content copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }

    function downloadConfig() {
      const blob = new Blob([configData], { type: "application/json" });
      const href = URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = href;
      link.download =  "config.json";
      document.body.appendChild(link);
      link.click();
    
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }

    function saveConfig() {
      let updatedConfig = getCurrentValue();
      postData('/updateConfig',{instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: templateName, config: updatedConfig})
        .then(data =>{
            setConfigData(JSON.stringify(data))
        })
        .catch(error => {
            console.log("Error occured on Saving: ", error);
            // setConfigData()
        }); 
    }

    function uploadConfigModal() {
      setOpen(true);
    }

    const cancelUploadRequest = (event, arg) => {
      setOpen(false);
  }

  const updateUploadedConfig = (event) => {
    setConfigCurrentData( event ) ;
    setOpen(false);
  }

  const cancelPreviewModal = (event, arg) => {
    setOpenPreviewModal(false);
  }

  const previewChanges = (event) => {
    let updatedConfig = getCurrentValue();
    // call preview
    setModifiedData(updatedConfig);
    setOpenPreviewModal(true);
  }

  function handleEditorValidation(markers) {
    // model markers
    if(markers.length > 0) {
      console.log('Have some errors');
      setValidCodeFlag(false);
      markers.forEach((marker) => console.log('onValidate:', marker.message));
    }
    else {
      setValidCodeFlag(true);
    }
  }

  function handleConfigChange(modifiedConfig, changes) {
    console.log('Have some changes: ', changes);
  }

  function beforeRequest(){
    setFlagLoader(1);
  }

  function postRespone(){
    setFlagLoader(0);
  }

  const [isValidCodeFlag, setValidCodeFlag] = useState(true);

    return (
      <div>
        <AppHeader />
        <Box className="progress-bar" sx={{ "display": flagLoader ? 'flex': 'none'}}>
              <CircularProgress  />
        </Box>
        {
          
        configData ? 
          <div >
            <h1> {templateName} </h1>
            
            <FileUploaderModal open={open} handleClose={cancelUploadRequest } showUploadedConfig={updateUploadedConfig}/>
            <DiffEditorModal open={openPreviewModal} handleClose={cancelPreviewModal} originalCode={configData} modifiedCode={modifiedData}/>
            <Box m={1} style={ {marginBottom:"20px", marginRight: "120px", textAlign: "right"}} display="flex" justifyContent="flex-end" alignItems="flex-end" >
              <ButtonGroup style={ {marginRight:"20px"}}>
                  
                    {configDataWithErrors?.errorCount>0 ?
                    <ButtonWithErrors config={configDataWithErrors} label="Show Errors"></ButtonWithErrors>: <></>}
                  
                  <Button onClick = { previewChanges }>Preview Changes</Button>
              </ButtonGroup>
              <ButtonGroup>
               
                <Button onClick = { uploadConfigModal }>Upload</Button>
                <Button onClick = { copyContent }>Copy</Button>
                <Button onClick = { downloadConfig }>Download</Button>
              </ButtonGroup>
              <ButtonGroup style={ {marginLeft:"20px"}}>
                <Button variant="contained" disabled={!isValidCodeFlag} onClick = {saveConfig}>Save Config</Button>
              </ButtonGroup>
            </Box>
            
            <Editor height="90vh" options={options}  defaultLanguage="json" value={configCurrentData} defaultValue={configData} onMount={handleEditorDidMount} /*onChange={handleConfigChange}*/ onValidate={handleEditorValidation} />
          </div>
        : 
          <div>
            <p>Config Data for this folder doesnot exist.</p>
          </div>
        }
         
      </div>
    );
}

export default ConfigEditor;