import React, { useEffect  } from 'react';
import Cookies from 'js-cookie';
import { makeStyles } from '@mui/styles';
import { useParams, useLocation } from "react-router-dom";
import Paper from '@mui/material/Paper';
import { TreeView } from "@mui/x-tree-view/TreeView";
import FolderIcon from "@mui/icons-material/Folder";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AppHeader from '../AppHeader';
import DynamicTreeView from '../common/treeViewComponent';
import Box from "@mui/material/Box";
import { TEMPLATE_SERVER_URL } from '../common/constants';

import { TreeItem } from "@mui/x-tree-view/TreeItem";

import './previewTemplate.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    textAlign: 'left'
  },
  leftDiv: {
    padding: '1%',
    paddingRight:0,
    maxWidth: '30%',
    height: '100%',
    textOverflow: 'ellipsis',
    borderRadius: '0 !important',
    backgroundColor: '#f0f0f0', // Background color for the left div
  },
  rightDiv: {
    padding: '1%',
    flex: 1,
    width: '60%',
    borderRadius: '0 !important',
    paddingTop:0,
    backgroundColor: '#e0e0e0', // Background color for the right div
  },
}));



function PreviewTemplate(props) {

    const accessToken=Cookies.get('accessToken')
    const instanceUrl= Cookies.get('instanceUrl')
    const userInfo = {
          username: Cookies.get('username'), 
          fullName: Cookies.get('userFullName'), 
          organizationId: Cookies.get('organizationId')
    };

    const location = useLocation();

    const { templateName } = useParams();

    const classes = useStyles();
    
    const [dirJSONS, setDirJSON] = React.useState({});

    const [currentfilePath, setCurrentfilePath] = React.useState('No File selected!');
    const [fileContent, setFileContent] = React.useState('');



    const previewFilePath = (path) => {
        console.log(path);
        let index = path.indexOf('.');
        if(index!==-1){
            setCurrentfilePath(path);
        }
        if(instanceUrl && accessToken){

            getData('/admin/previewFile',{instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 59, organizationId : userInfo.organizationId, folderName: templateName, path: path})
                .then(data =>{
                    setFileContent(data);
                  
            })
            .catch(error => {
                setFileContent('');
            });     
       }
    }

    useEffect(() => {
        if(instanceUrl && accessToken){

            let arrUrlRoutes = location.pathname.split('/');
            const templateName = arrUrlRoutes[arrUrlRoutes.length-1];

            getJSONData('/preview',{instanceUrl: instanceUrl, accessToken: accessToken, apiVersion: 57, organizationId : userInfo.organizationId, folderName: templateName})
                .then(data =>{
                    setDirJSON(data);
                  
            })
            .catch(error => {
                setDirJSON({});
            });    
        }
        

    },[instanceUrl,accessToken])

    async function getData(url='', data = {}) {
        // Default options are marked with *
        const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}&folderName=${data.folderName}&path=${data.path}`, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          // body:  JSON.stringify(get_templates_body)
          
        });
        return response.text(); // parses JSON response into native JavaScript objects
      }

      async function getJSONData(url='', data = {}) {
        // Default options are marked with *
        const response = await fetch(TEMPLATE_SERVER_URL + url + `?instanceUrl=${data.instanceUrl}&accessToken=${data.accessToken}&organizationId=${userInfo.organizationId}&folderName=${data.folderName}`, {
          mode: 'cors',
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          // body:  JSON.stringify(get_templates_body)
          
        });
        return response.json(); // parses JSON response into native JavaScript objects
      }

    return (
        <div>
            <AppHeader />
            <div className={classes.container}>
                <Paper className={classes.leftDiv}>
                    <Box sx={{ height: 700, flexGrow: 1, maxWidth: 400, textAlign: 'left', overflow: 'auto' }}>
                        <TreeView defaultCollapseIcon={<ChevronRightIcon />} defaultExpandIcon={<ExpandMoreIcon />}>
                            <TreeItem
                                key={dirJSONS.path}
                                nodeId={dirJSONS.path}
                                label={dirJSONS.name}
                                icon={dirJSONS.type === "folder" ? <FolderIcon /> : null}
                            >
                            <DynamicTreeView nodes={dirJSONS.children} previewFile={previewFilePath}/>
                            </TreeItem>
                        </TreeView>
                    </Box>
                </Paper>
                <Paper className={classes.rightDiv}>
                    <p className="fileLabel">{currentfilePath}</p>
                    <pre>
                        <p className="fileContent">{fileContent}</p>
                    </pre>
                </Paper>
            </div>
        </div>
    );


}


export default PreviewTemplate;